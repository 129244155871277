import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const TokenName = 'appran_token'; 
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const baseUrl = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3002' 
  : process.env.NEXT_PUBLIC_API_BASE_URL;


  useEffect(() => {
    const storedToken = localStorage.getItem(TokenName);
    
    if (storedToken) {
      setToken(storedToken);
      fetchCurrentUser(storedToken);
    } else {
      setIsLoggedIn(false);
    }
  }, [TokenName]);  
  

  const fetchCurrentUser = async (token) => {
    try {
      const response = await axios.get('/api/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setCurrentUser(response.data.user);
        setIsLoggedIn(true);
      } else {
        setCurrentUser(null);
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Error fetching current user:', error);
      setCurrentUser(null);
      setIsLoggedIn(false);
    }
  };

  const saveToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem(TokenName, newToken);
    fetchCurrentUser(newToken);
  };

  const removeToken = () => {
    setToken(null);
    localStorage.removeItem(TokenName);
    setCurrentUser(null);
    setIsLoggedIn(false);
    toast.success('Logout Successfully!');
  };

  const authContextValue = {
    TokenName,
    getToken: () => localStorage.getItem(TokenName),
    token,
    setToken: saveToken,
    currentUser,
    setCurrentUser,
    isLoggedIn,
    removeToken,
    baseUrl
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
