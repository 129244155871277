import { AuthProvider } from "@/context/AuthContext";
import "@/styles/globals.css"; 
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/theme.scss"
import "../styles/main.scss"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

function MyApp({ Component, pageProps }) {
  return (
    <AuthProvider>
      <ToastContainer />
      <Component {...pageProps} />
    </AuthProvider>
  );
}

export default MyApp;
